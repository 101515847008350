import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 0;
  width: 100%;
  background-color: #f0f0f0;

  .a {
    text-decoration: none;
  }
`;

export const Text = styled.h1`
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  margin: auto;
  display: table;
  margin-bottom: 30px;
  padding-top: 1px;
  font-size: 47px;

  @media (max-width: 680px) {
    margin-bottom: 50px;
    margin-top: 50px;
  }
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Sidebar = styled('div')`
  position: absolute;
  width: calc((100vw - 900px) / 2);
  height: 100%;
  min-height: 320px;
  max-height: calc(100vh - 100px);
  top: 160px;
  right: 20px;
  background-color: #fff;
  padding: 0 20px 20px;
  border-radius: 10px;

  &.sticky {
    position: fixed;
    top: 20px;
  }
  
  & .scrolling {
    overflow-y: auto;
    height: auto;
    max-height: calc(100% - 100px);
    border-bottom: 0;
  }

  & a:last-child div {
    border-bottom: none;
  }

  & div {
    font-size: 19px;
  }
`;

export const Wrapper = styled.div`
  padding-top: 50px;
  max-width: 800px;
  margin: auto;
  min-height: calc(100vh - 200px);
  background-color: #fff;
  padding: 0 20px 0 20px;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(80, 87, 91, 0.15);
  width: 100%;

  .a {
    text-decoration: none;
  }

  @media (max-width: 800px) {
    margin-top: -30px;
    max-width: 650px;
  }

  @media (max-width: 500px) {
    padding: 1px 30px;
    max-width: 400px;
  }
`;

export const Status = styled.p`
  margin-right: 20px;
  margin-top: 14px;
  font-size: 17px;
  color: ${status => (status !== 'UP' ? '#33D5D4' : '#ED4C67')};
  float: right !important;
`;

export const Component = styled.div`
  padding: 5px 0;
  font-size: 15px;
  color: #445d6e;
  font-family: 'Open Sans', sans-serif;
  border-bottom: 1px solid #e0e4e7;
  text-decoration: none;
  .a {
    text-decoration: none;
  }

  @media (max-width: 680px) {
    padding: 1px 30px;
  }
`;

export const Info = styled.p`
  color: #fff;
  font-size: 18px;
  font-weight: bold;
`;

export const BlockInfo = styled.div`
  height: 52px;
  max-width: 800px;
  background: #2980b7;
  padding: 15px;
  margin-bottom: 40px;
  border-radius: 10px;
`;

export const Header = styled.div`
  background: #1f6089;
  padding: 32px 32px 16px;
  width: 100%;

  @media (max-width: 720px) {
    padding: 32px 5px 16px;
  }
`;

export const Span = styled.span`
  font-size: 30px;
  margin-top: 50px;
  text-align: center;
  width: 40%;
  position: absolute;

  @media (max-width: 1400px) {
    width: 54%;
  }

  @media (max-width: 1280px) {
    width: 59%;
  }

  @media (max-width: 720px) {
    width: 85%;
  }

  @media (max-width: 480px) {
    width: 72%;
  }
`;

export const H2 = styled.h2`
  font-size: 30px;
  color: #445d6e;
  margin-top: 25px;
`;