import styled, {createGlobalStyle} from "styled-components"

export const LoginButton = styled.button`
  display: block;
  float: right;
  height: 34px;
  margin: -35px 0 0 0;
  transition: color 0.1s ease-out 0s, background-color 0.1s ease-out 0s,
  border-color 0.1s ease-out 0s, box-shadow 0.2s ease-out;
  font-size: 14px;
  font-family: Open Sans, Arial, sans-serif;
  font-weight: 700;
  line-height: 1em;
  padding: 10px 16px;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  border: 1px solid #fff;
  position: absolute;
  right: 30px;
  background-color: #1f6089;

  &:hover {
    background-color: #fff;
    color: #1f6089;
  }
`;

export const LogoutButton = styled.button`
  display: block;
  float: right;
  height: 34px;
  margin: -35px 0 0 0;
  transition: color 0.1s ease-out 0s, background-color 0.1s ease-out 0s,
  border-color 0.1s ease-out 0s, box-shadow 0.2s ease-out;
  font-size: 14px;
  font-family: Open Sans, Arial, sans-serif;
  font-weight: 700;
  line-height: 1em;
  padding: 10px 16px;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  border: 1px solid #fff;
  position: absolute;
  right: 30px;
  background-color: #1f6089;

  &:hover {
    background-color: #fff;
    color: #1f6089;
  }

  @media (max-width: 720px) {
    right: 10px;
    margin: 10px 10px;
  }
`;

export const ToastGlobalStyle = createGlobalStyle`
    .Toastify__toast-body {
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;