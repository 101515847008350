import React, {Component} from 'react'
import queryString from 'query-string'
import {Redirect, withRouter} from 'react-router'
import {connect} from 'react-redux'
import {ConfirmSubscriptionRequest, UnsubscribeRequest} from '../../store/actions/subscribe.actions'

const COUNTDOWN_TIME = 5; // redirect after this count of seconds

class Subscription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            errors: [],
            action: undefined,
            countdown: -1
        }
    }

    componentDidMount() {
        const params = queryString.parse(this.props.location.search);

        switch (params.action) {
            case "unsubscribe":
                this.setState({
                    action: {
                        [params.action.toLowerCase()]: true
                    }
                });
                this.props.unsubscribe(params.subscriptionId);
                break;
            case "confirm":
                this.setState({
                    action: {
                        [params.action.toLowerCase()]: true
                    }
                });
                this.props.confirmSubscription(params.subscriptionId, params.code);
                break;
            default:
                this.setState({
                    loading: false,
                    countdown: COUNTDOWN_TIME,
                    errors: [{
                        message: params.action ? `Unknown subscription action ${params.action}` : "Subscription action is not set"
                    }]
                });

                this.startCounter();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let update = {};

        ["loading", "errors", "subscription"].forEach(property => {
            if (prevProps[property] !== this.props[property]) {
                update[property] = this.props[property];
            }
        });

        if (Object.keys(update).length > 0) {
            if (update.hasOwnProperty("errors")) {
                update.countdown = COUNTDOWN_TIME;

                this.startCounter();
            }

            this.setState(update);
        }
    }

    startCounter() {
        this.counter = setInterval(() => {
            if (this.state.countdown > 0) {
                this.setState(({countdown}) => ({
                    countdown: countdown - 1
                }));
            } else {
                clearInterval(this.counter);
            }
        }, 1000);
    }

    render() {
        return <>
            {this.state.loading ? (
                <p>Loading...</p>
            ) : this.state.errors.length > 0 ? (
                <>
                    {this.state.errors.map((error, index) => (
                        <p key={index}>{error.message}</p>
                    ))}
                    <p>You will be redirected to main page in <b>{this.state.countdown}</b> seconds</p>
                    {this.state.countdown <= 0 && (
                        <Redirect push={false} to={{
                            pathname: "/",
                            state: {
                                notify: this.state.errors.map(error => ({
                                    type: "error",
                                    message: error.message
                                }))
                            }
                        }}/>
                    )}
                </>
            ) : (
                <Redirect to={{
                    pathname: `/${this.state.subscription ? this.state.subscription.client || "" : ""}`,
                    state: {
                        notify: [{
                            type: "success",
                            message: this.state.action.confirm
                                ? "Subscription was successfully confirmed"
                                : (this.state.action.unsubscribe
                                    ? "You have successfully unsubscribed"
                                    : "Status of subscription was successfully changed")
                        }]
                    }
                }}/>
            )}
        </>
    }
}

const mapStateToProps = state => ({
    subscription: state.subscribe.subscription,
    loading: state.subscribe.loading,
    errors: state.subscribe.errors
});

const mapActionsToProps = dispatch => ({
    confirmSubscription: (subscriptionId, code) => dispatch(ConfirmSubscriptionRequest(subscriptionId, code)),
    unsubscribe: subscriptionId => dispatch(UnsubscribeRequest(subscriptionId))
});

export default withRouter(
    connect(
        mapStateToProps,
        mapActionsToProps,
    )(Subscription),
)