import React from 'react'
import {Provider} from "react-redux";
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {ClientPage, HomePage, LoginPage} from "./pages"
import {CustomRoute, Subscription} from './components';
import {createBrowserHistory} from "history";
import rootStore from "./store";

const customHistory = createBrowserHistory();
const App = () => (
    <Provider store={rootStore}>
        <Router histroy={customHistory}>
            <Switch>
                <CustomRoute exact path="/subscription" component={Subscription}/>
                <CustomRoute exact path="/administration/login" component={LoginPage}/>
                <CustomRoute exact path="/:client" component={ClientPage}/>
                <CustomRoute exact path="/" component={HomePage}/>
            </Switch>
        </Router>
    </Provider>
);

export default App;