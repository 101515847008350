import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {SubscribeRequest} from "../../store/actions/subscribe.actions";
import {FireworkSpinner} from "react-spinners-kit";
import {
    Checkbox,
    Close,
    Container,
    Input,
    InputGroup,
    InputWrapper,
    Label,
    Privacy,
    Radio,
    RadioGroup,
    SubmitButton,
    SwitchGroupTitle,
    Text,
    Wrapper,
} from "./styled";
import Terms from "./Terms";

class Popup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showTerms: false,
            values: {
                client: props.client || "",
                email: "",
                termsAccepted: false,
                incidentsEnabled: false,
                downtimesEnabled: false,
                downtimeReportType: 'ASAP',
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            loading: false,
            errors: [],
            ...this.initialErrorState
        };
    }

    get initialErrorState() {
        return {
            clientError: undefined,
            emailError: undefined,
            termsAcceptedError: undefined
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let update = {};

        ["loading", "errors"].forEach(property => {
            if (prevProps[property] !== this.props[property]) {
                update[property] = this.props[property];
            }
        });

        if (Object.keys(update).length > 0) {
            if (update.hasOwnProperty("errors")) {
                this.props.onSubscribeError(update.errors);
            } else {
                if (this.isFormValid() && !update.loading) {
                    this.props.onSubscribeSuccess(this.state.values.email);
                }
            }

            this.setState(update);
        }
    }

    handleBeforeSubmit = event => {
        event.preventDefault();

        this.validate(this.onValidationSuccess);
    };

    onValidationSuccess = () => {
        this.props.subscribe(
            this.state.values.email,
            this.state.values.client,
            this.state.values.timezone,
            this.state.values.incidentsEnabled,
            this.state.values.downtimesEnabled,
            this.state.values.downtimesEnabled ? this.state.values.downtimeReportType : null,
        );
    };

    onValidationError = errors => {
        this.setState({...errors});
    };

    clearErrors = () => {
        this.setState({...this.initialErrorState});
    };

    toggleTermsAccepted = () => {
        this.setState({
            values: {
                ...this.state.values,
                termsAccepted: !this.state.values.termsAccepted
            }
        });
    };

    toggleIncidentsAccepted = () => {
        this.setState({
            values: {
                ...this.state.values,
                incidentsEnabled: !this.state.values.incidentsEnabled
            }
        });
    };

    toggleDowntimesAccepted = () => {
        this.setState({
            values: {
                ...this.state.values,
                downtimesEnabled: !this.state.values.downtimesEnabled
            }
        });
    };
    
    setDowntimesOption = (e) => {
        this.setState({
            values: {
                ...this.state.values,
                downtimeReportType: e.currentTarget.value
            }
        });
    };

    updateEmailValue = event => {
        this.setState({
                values: {
                    ...this.state.values,
                    email: event.target.value
                }
            }
        );
    };

    // @formatter:off
    validate = (onSuccess = () => {}) => {
        this.clearErrors();
        this.validateForm()
            .then(onSuccess)
            .catch(this.onValidationError);
    };
    // @formatter:on

    toggleShowTerms = () => {
        this.setState({
            showTerms: !this.state.showTerms,
        })
    };

    checkFormValid = () => {
        const errors = this.validateFields(this.state.values);

        if (!(errors.constructor === Object && Object.keys(errors).length === 0)) {
            return errors;
        }
    };

    isFormValid = () => {
        return this.checkFormValid() === undefined;
    };

    validateForm = () => {
        return new Promise((resolve, reject) => {
            const errors = this.checkFormValid();

            if (errors) {
                reject(errors);
            }

            resolve(this.state.values.email);
        });
    };

    validateFields = values => {
        const errors = {};

        if (!values.client) {
            errors.clientError = "Client is required, but not provided";
        }

        if (!values.email) {
            errors.emailError = "Email is wrong";
        }

        if (!values.termsAccepted) {
            errors.termsAcceptedError = "Privacy policy should be accepted";
        }

        if (!values.incidentsEnabled && !values.downtimesEnabled ) {
            errors.notificationType = "At least one option have to be selected";
        }

        return errors;
    };

    render() {
        return (
            <Container>
                <Close type="reset"
                       onClick={this.props.closePopup}>
                    <i className="fa fa-window-close"/>
                </Close>
                <Wrapper>
                    <Text>Get an email if API status changes</Text>
                    <InputWrapper onSubmit={this.handleBeforeSubmit}>
                        <SwitchGroupTitle>Inform me about ...</SwitchGroupTitle>
                        <InputGroup>
                            <Checkbox type="checkbox"
                                    id="incidentsAndMaintanance"
                                    name="incidentsAndMaintanance"
                                    onClick={this.toggleIncidentsAccepted}/>
                            <Label htmlFor="incidentsAndMaintanance">&nbsp;... incidents and further announcements (like e.g. planned maintenance windows)</Label>&nbsp;
                        </InputGroup>

                        <InputGroup>
                            <Checkbox type="checkbox"
                                    id="downtimes"
                                    name="downtimes"
                                    onClick={this.toggleDowntimesAccepted}/>
                            <Label htmlFor="downtimes">&nbsp;... downtimes (and associated uptime messages)</Label>&nbsp;
                        </InputGroup>

                        <InputGroup>
                            <SwitchGroupTitle>Don't inform me about downtimes more often than ...</SwitchGroupTitle>

                            <RadioGroup>
                                <Radio type="radio"
                                    id="tenMinutes"
                                    name="downtimesOption"
                                    value="10MIN"
                                    checked={this.state.values.downtimeReportType === '10MIN'}
                                    disabled = { !this.state.values.downtimesEnabled }
                                    onChange={this.setDowntimesOption}/>
                                <Label htmlFor="tenminutes">&nbsp;... once every 10 minutes</Label>&nbsp;
                            </RadioGroup>

                            <RadioGroup>
                                <Radio type="radio"
                                    id="hour"
                                    name="downtimesOption"
                                    value="1H"
                                    checked={this.state.values.downtimeReportType === '1H'}
                                    disabled = { !this.state.values.downtimesEnabled }
                                    onChange={this.setDowntimesOption}/>
                                <Label htmlFor="hour">&nbsp;... once an hour</Label>&nbsp;
                            </RadioGroup>

                            <RadioGroup>
                                <Radio type="radio"
                                       id="four_hours"
                                       name="downtimesOption"
                                       value="4H"
                                       checked={this.state.values.downtimeReportType === '4H'}
                                       disabled = { !this.state.values.downtimesEnabled }
                                       onChange={this.setDowntimesOption}/>
                                <Label htmlFor="four_hours">&nbsp;... once every 4 hours</Label>&nbsp;
                            </RadioGroup>

                            <RadioGroup>
                                <Radio type="radio"
                                       id="one_day"
                                       name="downtimesOption"
                                       value="24H"
                                       checked={this.state.values.downtimeReportType === '24H'}
                                       disabled = { !this.state.values.downtimesEnabled }
                                       onChange={this.setDowntimesOption}/>
                                <Label htmlFor="one_day">&nbsp;... once a day</Label>&nbsp;
                            </RadioGroup>

                            <RadioGroup>
                                <Radio type="radio"
                                       id="asap"
                                       name="downtimesOption"
                                       value="ASAP"
                                       checked={this.state.values.downtimeReportType === 'ASAP'}
                                       disabled = { !this.state.values.downtimesEnabled }
                                       onChange={this.setDowntimesOption}/>
                                <Label htmlFor="asap">&nbsp;Receive downtime messages immediately on each status change</Label>&nbsp;
                            </RadioGroup>
                        </InputGroup>

                        <Input type="email"
                               id="email"
                               name="email"
                               required
                               placeholder="E-Mail address"
                               value={this.state.email}
                               onChange={this.updateEmailValue}/>
                        <div>
                            <Checkbox type="checkbox"
                                      id="accepted"
                                      name="accepted"
                                      onClick={this.toggleTermsAccepted}/>
                            <Label htmlFor="accepted">&nbsp;By subscribing you agree to our </Label>&nbsp;
                            <Privacy onClick={this.toggleShowTerms}>
                                Privacy Policy
                            </Privacy>
                            {this.state.showTerms && (<Terms/>)}<br/>

                        </div>
                        <div>
                            <SubmitButton type="submit"
                                          disabled={this.state.loading || !this.isFormValid()}>
                                Subscribe&nbsp;
                                <FireworkSpinner loading={this.state.loading}
                                                 color="#FFF"
                                                 size={20}/>
                            </SubmitButton>
                        </div>
                    </InputWrapper>
                </Wrapper>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.subscribe.loading,
    errors: state.subscribe.errors
});

const mapActionsToProps = dispatch => ({
    subscribe: (
        email,
        client,
        timezone,
        incidentsEnabled,
        downtimesEnabled,
        downtimeReportType,
    ) => dispatch(
        SubscribeRequest(
            email,
            client,
            timezone,
            incidentsEnabled,
            downtimesEnabled,
            downtimeReportType,
        )
    )
});

const defaultProps = {
    initialValues: {}
};

const propTypes = {
    client: PropTypes.string.isRequired,
    onSubscribeSuccess: PropTypes.func.isRequired,
};

Popup.propTypes = propTypes;
Popup.defaultProps = defaultProps;

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(Popup);