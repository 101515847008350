import React from "react"
import {BlockInfo, Component, Info, Status, StatusSize, Text, TextSize, Wrapper,} from "./styled"

const ApiStatusBlock = ({statuses}) => {
    const STATUS = {
        UP: "Operational",
        DOWN: "Down",
        INIT: "Initialization",
        MAINTENANCE: "Maintenance",
    };

    return (
        <>{statuses && statuses.length > 0 ? (
            <Wrapper>
                <BlockInfo className="fade-in"
                           warn={statuses.some(item => item.status === "DOWN")}>
                    <Info className="fade-in">
                        {statuses.some(item => item.status === "DOWN")
                            ? "Some systems are experiencing issues"
                            : "All Systems Operational"}
                    </Info>
                </BlockInfo>

                {statuses.map((item, index) => (
                    <Component key={index} className="fade-in">
                        <TextSize className="fade-in">
                            <Text className="fade-in">
                                {item.api}
                            </Text>
                        </TextSize>
                        <StatusSize className="fade-in">
                            <Status className="fade-in" status={item.status}>
                                {STATUS[item.status]}
                            </Status>
                        </StatusSize>
                    </Component>
                ))}
            </Wrapper>
        ) : null
        }</>
    )
};

export default ApiStatusBlock