import styled from 'styled-components'

export const Text = styled.span`
  font-size: 19px;
  color: #445d6e;
  font-family: 'Open Sans',sans-serif;

  a {
        text-decoration: none;
    }

    @media (max-width: 480px) {
        font-size: 18px;
    }
`;

export const H1 = styled.h1`
  margin-top: 25px;
  margin-bottom: 20px;
  font-size: 27px;
  color: black;
  font-family: 'Open Sans',sans-serif;

    @media (max-width: 480px) {
        font-size: 23px;
    }
`;

export const H2 = styled.h2`
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 23px;
  color: black;
  font-family: 'Open Sans',sans-serif;

  @media (max-width: 480px) {
        font-size: 20px;
    }
`;