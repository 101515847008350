import {Auth} from 'aws-amplify'

export const login = (email, password) => {
    return Auth.signIn(email, password)
};

export const logout = () => {
    return Auth.signOut()
};

export const checkAuth = () => {
    return Auth.currentAuthenticatedUser()
};
