import * as types from './types'

export const StatusRequest = client => ({
    type: types.STATUS_REQUEST,
    client
});

export const StatusSuccess = statuses => ({
    type: types.STATUS_SUCCESS,
    statuses
});

export const StatusFailure = response => ({
    type: types.STATUS_FAILURE,
    response
});