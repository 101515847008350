import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 5px;
  max-width: 800px;
  margin: auto;
  min-height: calc(100vh - 245px);
  background-color: #f0f0f0;

  @media (max-width: 800px) {
    margin: auto;
  }

  @media (max-width: 680px) {
    padding: 1px 30px;
  }
`;

export const Container = styled.div`
  max-width: 100%;
  background-color: #f0f0f0;
`;

export const Text = styled.h1`
  padding-left: 0;
  padding-bottom: 50px;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  background-color: #1f6089;
  text-align: center;
  padding-top: 42px;
  margin-bottom: 20px;

  @media (max-width: 720px) {
    font-size: 30px;
    padding-left: 55px;
    padding-top: 54px;
  }

  @media (max-width: 480px) {
    padding-left: 0;
  }
`;

export const Subscribe = styled.button`
  display: block;
  float: right;
  height: 34px;
  transition: color 0.1s ease-out 0s, background-color 0.1s ease-out 0s,
    border-color 0.1s ease-out 0s, box-shadow 0.2s ease-out;
  font-size: 14px;
  font-family: Open Sans, Arial, sans-serif;
  font-weight: 700;
  line-height: 1em;
  padding: 10px 16px;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  border: 1px solid #fff;
  position: absolute;
  right: 30px;
  background-color: #003d8f;

  &:hover {
    background-color: #fff;
    color: #003d8f;
  }
`;

export const ButtonPosition = styled.div`
  width: 10%;
  height: 45px;
  margin: auto;
  position: relative;
  margin-top: -59px;

  @media (max-width: 1400px) {
    width: 12%;
  }

  @media (max-width: 720px) {
    width: 23%;
  }

  @media (max-width: 480px) {
    width: 40%;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
`;