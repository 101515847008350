import axios from 'axios'

export const getIncidents = client => axios
    .get("incidents", {params: {client}})
    .then(response => response.data);

export const addIncident = incident => axios
    .post("incidents", incident)
    .then(response => response.data);

export const editIncident = (incidentId, incident) => axios
    .patch(`incidents/${incidentId}`, incident)
    .then(response => response.data);

export const removeIncident = incidentId => axios
    .delete(`incidents/${incidentId}`)
    .then(response => response.data);