import {all, call, put, takeEvery} from 'redux-saga/effects'
import * as types from '../actions/types'
import {ClientsFailure, ClientsSuccess,} from '../actions/clients.actions'
import {getClients} from '../fetches/clients.fetch'

function* clients() {
    try {
        const data = yield call(getClients);
        yield put(ClientsSuccess(data));
    } catch (error) {
        yield put(ClientsFailure(error));
    }
}

export function* clientsSaga() {
    yield all([takeEvery(types.CLIENTS_REQUEST, clients)])
}
