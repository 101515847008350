export const CLIENTS_REQUEST = 'CLIENTS_REQUEST';
export const CLIENTS_SUCCESS = 'CLIENTS_SUCCESS';
export const CLIENTS_FAILURE = 'CLIENTS_FAILURE';

export const CHECK_AUTH_REQUEST = 'CHECK_AUTH_REQUEST';
export const CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS';
export const CHECK_AUTH_FAILURE = 'CHECK_AUTH_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const TOGGLE_AUTH_LOADING = 'TOGGLE_AUTH_LOADING';
export const STATUS_REQUEST = 'STATUS_REQUEST';
export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const STATUS_FAILURE = 'STATUS_FAILURE';

export const INCIDENTS_LOAD_REQUEST = 'INCIDENTS_LOAD_REQUEST';
export const INCIDENTS_LOAD_SUCCESS = 'INCIDENTS_LOAD_SUCCESS';
export const INCIDENTS_LOAD_FAILURE = 'INCIDENTS_LOAD_FAILURE';
export const INCIDENTS_TOGGLE_LOADING = 'INCIDENTS_TOGGLE_LOADING';

export const INCIDENT_ADD_REQUEST = 'INCIDENT_ADD_REQUEST';
export const INCIDENT_ADD_SUCCESS = 'INCIDENT_ADD_SUCCESS';
export const INCIDENT_ADD_FAILURE = 'INCIDENT_ADD_FAILURE';

export const INCIDENT_EDIT_REQUEST = 'INCIDENT_EDIT_REQUEST';
export const INCIDENT_EDIT_SUCCESS = 'INCIDENT_EDIT_SUCCESS';
export const INCIDENT_EDIT_FAILURE = 'INCIDENT_EDIT_FAILURE';

export const INCIDENT_REMOVE_REQUEST = 'INCIDENT_REMOVE_REQUEST';
export const INCIDENT_REMOVE_SUCCESS = 'INCIDENT_REMOVE_SUCCESS';
export const INCIDENT_REMOVE_FAILURE = 'INCIDENT_REMOVE_FAILURE';

export const INCIDENT_TOGGLE_EDITING = 'INCIDENT_TOGGLE_EDITING';
export const INCIDENT_TOGGLE_SAVING = 'INCIDENT_TOGGLE_SAVING';
export const INCIDENT_TOGGLE_REMOVING = 'INCIDENT_TOGGLE_REMOVING';
export const TOGGLE_ADD_INCIDENT_LOADING = 'TOGGLE_ADD_INCIDENT_LOADING';
// export const INCIDENT_FIELD_EDIT = 'INCIDENT_FIELD_EDIT';

export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILURE = 'SUBSCRIBE_FAILURE';
export const SUBSCRIPTION_GET_REQUEST = 'SUBSCRIPTION_GET_REQUEST';
export const SUBSCRIPTION_GET_SUCCESS = 'SUBSCRIPTION_GET_SUCCESS';
export const SUBSCRIPTION_GET_FAILURE = 'SUBSCRIPTION_GET_FAILURE';
export const SUBSCRIPTION_CONFIRM_REQUEST = 'SUBSCRIPTION_CONFIRM_REQUEST';
export const SUBSCRIPTION_CONFIRM_SUCCESS = 'SUBSCRIPTION_CONFIRM_SUCCESS';
export const SUBSCRIPTION_CONFIRM_FAILURE = 'SUBSCRIPTION_CONFIRM_FAILURE';
export const UNSUBSCRIBE_REQUEST = 'UNSUBSCRIBE_REQUEST';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_FAILURE = 'UNSUBSCRIBE_FAILURE';