import styled from 'styled-components';

export const Wrapper = styled.div`
    max-width: 800px;
    margin: auto;
    padding: 16px 0 20px 0;
    border-top: 1px solid #E0E4E7;
    float: left;
    width: 100%;
    bottom: 0px;
`;

export const Container = styled.div`
    max-width: 800px;
    margin: auto;
    background-color: #f0f0f0;

    @media (max-width: 680px) {
    padding: 1px 30px 1px;
  }
`;

export const Text = styled.div`
    padding-left: 10px;
    font-family: 'Open Sans',sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: #637986;
    position: absolute;
    bottom: 0;
`;

export const Div = styled.div`
    background-color: #f0f0f0;
    width: 100%;
    position: absolute;
    padding-top: 15px;
`;