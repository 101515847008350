import React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {Footer} from "../../components";
import {CheckAuthRequest, LogoutRequest} from "../../store/actions/auth.actions";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import {Container, Text} from "../../pages/ClientPage/styled";
import {toast, ToastContainer} from "react-toastify";
import {LoginButton, LogoutButton, ToastGlobalStyle} from "./styled";

class PageWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        this.props.checkAuth();

        let notify = [];

        const locationState = this.props.location.state;

        // set from redirect with state (e.g. <Redirect to={{pathname: "/", state: {notify: [{type: "success", message: "abc"}]}}})
        if (locationState && Array.isArray(locationState.notify) && locationState.notify.length > 0) {
            // cleanup location state from notification
            this.props.history.replace(this.props.location.pathname, null);
            notify.push.apply(notify, locationState.notify);
        }

        // set from props during component mount (e.g. <PageWrapper notify={[{type: "error", message: "xyz"}]})
        if (Array.isArray(this.props.notify) && this.props.notify.length > 0) {
            notify.push.apply(notify, this.props.notify);
        }

        // save got notifications to state (componentDidUpdate will be called)
        notify.length > 0 && this.setState({
            notify: notify
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let update = {};

        ["notify"].forEach(property => {
            if (prevProps[property] !== this.props[property]) {
                update[property] = this.props[property];
            }
        });

        if (Object.keys(update).length > 0) {
            this.setState(update);
        }

        const notify = this.state.notify;

        if (notify && JSON.stringify(prevState.notify) !== JSON.stringify(notify)) {
            this.showToasts(notify);
        }
    }

    handleLogout = () => {
        this.props.logout();
    };

    handleLoginRedirect = () => {
        this.props.history.replace("/administration/login")
    };

    showToasts = notify => {
        if (Array.isArray(notify) && notify.length > 0) {
            notify.forEach(entry => {
                toast(entry.message, {
                    type: toast.TYPE[entry.type.toUpperCase()],
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
            })
        }
    };

    render() {
        return (
            <>
                <ToastContainer/>
                <ToastGlobalStyle/>
                <Container>
                    <Text className="fade-in">
                        {this.props.title}
                        {this.props.isLoggedIn ? (
                            <LogoutButton className="fade-in" onClick={this.handleLogout}>
                                Logout
                            </LogoutButton>
                        ) : (
                            <LoginButton className="fade-in" onClick={this.handleLoginRedirect}>
                                Login
                            </LoginButton>
                        )}
                    </Text>
                    <>{this.props.children}</>
                    <Footer/>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
});

const mapActionsToProps = dispatch => ({
    checkAuth: () => dispatch(CheckAuthRequest()),
    logout: () => dispatch(LogoutRequest()),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapActionsToProps,
    )(PageWrapper),
)