import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "core-js";
import * as serviceWorker from "./serviceWorker";

import "./global.css";

import App from "./App";

ReactDOM.render(
    <App/>,
    document.getElementById('root'),
);

serviceWorker.unregister();