import {all, fork} from 'redux-saga/effects'
import {clientsSaga} from './clients.saga'
import {authSaga} from './auth.saga'
import {incidentsSaga} from './incidents.saga'
import {statusSaga} from './status.saga'
import {subscribeSaga} from './subscribe.saga';

export default function* rootSaga() {
    yield all([
        fork(clientsSaga),
        fork(authSaga),
        fork(incidentsSaga),
        fork(statusSaga),
        fork(subscribeSaga)
    ])
}