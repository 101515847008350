import {combineReducers} from 'redux'
import authReducer from './auth.reducer'
import clientsReducer from './clients.reducer'
import incidentsReducer from './incidents.reducer'
import statusesReducer from './statuses.reducer'
import subscribeReducer from './subscribe.reducer';

const reducers = combineReducers({
    auth: authReducer,
    clients: clientsReducer,
    incidents: incidentsReducer,
    statuses: statusesReducer,
    subscribe: subscribeReducer
});

export default reducers
