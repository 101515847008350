import * as types from '../actions/types'

const emptyIncident = {
    description: '',
    status: '',
    from: '',
    apis: [],
};
const initialState = {
    incident: {...emptyIncident},
    items: [],
    loading: false,
    adding: false,
    removing: false,
    saving: false,
    errors: []
};

function splitClients(incident) {
    incident.clients = incident.client.split(', ');
    return incident;
}

const incidentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.INCIDENTS_LOAD_SUCCESS: {
            return {
                ...state,
                items: action.incidents.map(incident => {
                    incident = splitClients(incident);
                    return {
                        edit: false,
                        data: incident
                    };
                }),
            };
        }
        case types.INCIDENT_ADD_SUCCESS: {
            action.incident = splitClients(action.incident);
            return {
                ...state,
                adding: false,
                editing: false,
                incident: {...emptyIncident},
                items: [{
                    edit: false,
                    data: action.incident
                }, ...state.items],
            };
        }
        case types.INCIDENT_EDIT_SUCCESS: {
            action.incident = splitClients(action.incident);
            return {
                ...state,
                editing: false,
                incident: {...emptyIncident},
                items: state.items
                    .map(incident => incident.data.incidentId === action.incident.incidentId ? {
                        edit: false,
                        data: action.incident
                    } : incident)
            };
        }
        case types.INCIDENT_REMOVE_SUCCESS:
            return {
                ...state,
                incident: {...emptyIncident},
                items: [...state.items.filter(incident => incident.data.incidentId !== action.incidentId)],
            };
        case types.INCIDENTS_TOGGLE_LOADING: {
            return {
                ...state,
                loading: !state.loading,
            }
        }
        case types.INCIDENT_TOGGLE_SAVING: {
            return {
                ...state,
                saving: !state.saving,
            }
        }
        case types.INCIDENT_TOGGLE_REMOVING: {
            return {
                ...state,
                removing: !state.removing,
            }
        }
        case types.INCIDENT_TOGGLE_EDITING: {
            if (action.incident.incidentId) {
                // edit incident
                let incidents = [...state.items];
                const editIndex = incidents.findIndex(incident => incident.data.incidentId === action.incident.incidentId);

                incidents[editIndex].edit = !incidents[editIndex].edit;

                if (incidents[editIndex].edit) {
                    // save original incident to restore, if edit canceled
                    incidents[editIndex].original = {...incidents[editIndex].data};
                } else {
                    // restore original incident, if canceled edit
                    incidents[editIndex].data = {...incidents[editIndex].original};

                    delete incidents[editIndex].original;
                }

                return {
                    ...state,
                    editing: !state.editing,
                    incident: incidents[editIndex].edit ? incidents[editIndex].data : {...emptyIncident},
                    items: [...incidents],
                };
            } else {
                // add incident
                return {
                    ...state,
                    adding: !state.adding,
                    editing: !state.editing,
                    incident: action.incident
                };
            }
        }
        case types.INCIDENT_ADD_FAILURE:
        case types.INCIDENTS_LOAD_FAILURE:
        case types.INCIDENT_EDIT_FAILURE:
        case types.INCIDENT_REMOVE_FAILURE: {
            return {
                ...state,
                errors: action.response.errors
            };
        }
        case types.INCIDENT_ADD_REQUEST:
        case types.INCIDENTS_LOAD_REQUEST:
        case types.INCIDENT_EDIT_REQUEST:
        case types.INCIDENT_REMOVE_REQUEST:
        default: {
            return state;
        }
    }
};

export default incidentsReducer