import {all, call, put, takeEvery} from "redux-saga/effects";
import * as types from "../actions/types";
import {StatusFailure, StatusSuccess} from "../actions/status.actions";
import {getStatuses} from "../fetches/status.fetch";

function* status(action) {
    try {
        const {data} = yield call(getStatuses, action.client);
        yield put(StatusSuccess(data));
    } catch (err) {
        yield put(StatusFailure(err));
    }
}

export function* statusSaga() {
    yield all([takeEvery(types.STATUS_REQUEST, status)]);
}