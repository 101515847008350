import * as types from '../actions/types'

const initialState = {
    statuses: [],
    loading: false,
    errors: [],
};

const statusesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.STATUS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case types.STATUS_SUCCESS: {
            return {
                ...state,
                loading: false,
                statuses: action.statuses
            }
        }
        case types.STATUS_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.response.errors
            }
        }
        default:
            return state
    }
};

export default statusesReducer