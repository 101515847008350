import {all, call, put, takeEvery} from 'redux-saga/effects'
import * as types from '../actions/types'
import {
    CheckAuthFailure,
    CheckAuthSuccess,
    LoginFailure,
    LoginSuccess,
    LogoutFailure,
    LogoutSuccess,
    ToggleAuthLoading,
} from '../actions/auth.actions'
import {checkAuth, login, logout} from '../fetches/auth.fetch'

function* loginRequest(action) {
    try {
        const {history} = action.data;
        yield put(ToggleAuthLoading());
        const data = yield call(
            login,
            action.data.email,
            action.data.password,
        );
        if (data.signInUserSession.idToken.jwtToken) {
            history.replace('/');
            yield put(LoginSuccess(data));
            yield put(ToggleAuthLoading())
        }
    } catch (error) {
        yield put(LoginFailure(error));
        yield put(ToggleAuthLoading())
    }
}

function* checkAuthRequest() {
    try {
        const user = yield call(checkAuth);
        yield put(CheckAuthSuccess(user))
    } catch (error) {
        yield put(CheckAuthFailure(error))
    }
}

function* logoutRequest() {
    try {
        const user = yield call(logout);
        yield put(LogoutSuccess(user))
    } catch (error) {
        yield put(LogoutFailure(error))
    }
}

export function* authSaga() {
    yield all([
        takeEvery(types.LOGIN_REQUEST, loginRequest),
        takeEvery(types.CHECK_AUTH_REQUEST, checkAuthRequest),
        takeEvery(types.LOGOUT_REQUEST, logoutRequest),
    ])
}