import {all, call, put, takeEvery} from "redux-saga/effects";
import * as types from "../actions/types";
import {confirmSubscription, getSubscription, subscribe, unsubscribe,} from "../fetches/subscriptions.fetch";
import {
    ConfirmSubscriptionFailure,
    ConfirmSubscriptionSuccess,
    GetSubscriptionFailure,
    GetSubscriptionSuccess,
    SubscribeFailure,
    SubscribeSuccess,
    UnsubscribeFailure,
    UnsubscribeSuccess,
} from "../actions/subscribe.actions";

function* subscribeRequest(action) {
    try {
        const {data} = yield call(subscribe,
            action.email,
            action.client,
            action.timezone,
            action.incidentsEnabled,
            action.downtimesEnabled,
            action.downtimeReportType,
        );
        yield put(SubscribeSuccess(data));
    } catch (error) {
        yield put(SubscribeFailure(error))
    }
}

function* unsubscribeRequest(action) {
    try {
        const {data} = yield call(unsubscribe, action.subscriptionId);
        yield put(UnsubscribeSuccess(data));
    } catch (error) {
        yield put(UnsubscribeFailure(error))
    }
}

function* getSubscriptionRequest(action) {
    try {
        const {data} = yield call(getSubscription, action.subscriptionId);
        yield put(GetSubscriptionSuccess(data));
    } catch (error) {
        yield put(GetSubscriptionFailure(error))
    }
}

function* confirmRequest(action) {
    try {
        const {data} = yield call(confirmSubscription, action.subscriptionId, action.code);
        yield put(ConfirmSubscriptionSuccess(data))
    } catch (error) {
        yield put(ConfirmSubscriptionFailure(error))
    }
}

export function* subscribeSaga() {
    yield all([
        takeEvery(types.SUBSCRIBE_REQUEST, subscribeRequest),
        takeEvery(types.SUBSCRIPTION_GET_REQUEST, getSubscriptionRequest),
        takeEvery(types.UNSUBSCRIBE_REQUEST, unsubscribeRequest),
        takeEvery(types.SUBSCRIPTION_CONFIRM_REQUEST, confirmRequest)
    ])
}