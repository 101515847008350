import React from "react";
import {ModalContainer, ModalWindow} from "./styles";

const ConfirmationDialog = ({children}) => {
    return (
        <ModalContainer>
            <ModalWindow>{children}</ModalWindow>
        </ModalContainer>
    )
};

export default ConfirmationDialog