import React, {useState} from 'react'
import {MultiSelect as ReactMultiSelect} from 'react-multi-select-component'
import styles from './multiselect.css'

const MultiSelect = ({label, items, selectedItems, handleChange}) => {
    const [selected, setSelected] = useState((selectedItems || []).map(item => ({value: item, label: item})));

    return (<>
        <label className={"form-label"}>{label}</label>
        <ReactMultiSelect className={styles.rmsc}
                          options={items.map(item => ({value: item, label: item}))}
                          value={selected}
                          onChange={options => {
                              setSelected(options);
                              handleChange(options);
                          }}/>
    </>);
};

export default MultiSelect