import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {FireworkSpinner} from "react-spinners-kit";
import {Backdrop, ButtonPosition, Subscribe, Wrapper} from "./styled";
import {StatusRequest} from "../../store/actions/status.actions";
import {IncidentsRequest} from "../../store/actions/incidents.actions";
import {ApiStatusBlock, IncidentsBlock, PageWrapper, SubscriptionPopup} from "../../components";

/**
 * Page of concrete client
 */
class ClientPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            client: undefined,
            showPopup: false,
            errors: [],
            notify: []
        }
    }

    componentDidMount() {
        const client = this.props.computedMatch.params.client;

        this.props.loadStatuses(client);
        this.props.loadIncidents(client);

        this.setState({
            client: client
        })
    }

    togglePopup = () => {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    };

    onSubscribeSuccess = email => {
        this.togglePopup();
        this.setState({
            notify: [{
                type: "success",
                message: `${email} was successfully subscribed for updates on ${this.state.client}`
            }]
        })
    };

    onSubscribeError = errors => {
        this.togglePopup();

        if (errors.length > 0) {
            const notify = errors.map(error => ({
                type: "error",
                message: error.message
            }));

            this.setState({
                notify: notify
            })
        }

    };

    render() {
        return (
            <PageWrapper title={`${this.state.client} PSD2 API Status`}
                         notify={this.state.notify}>
                <ButtonPosition>
                    <Subscribe onClick={this.togglePopup}>
                        Subscribe
                    </Subscribe>
                </ButtonPosition>
                {this.state.showPopup && (
                    <SubscriptionPopup client={this.state.client}
                                       closePopup={this.togglePopup}
                                       onSubscribeSuccess={this.onSubscribeSuccess}
                                       onSubscribeError={this.onSubscribeError}/>
                )}
                <Wrapper>
                    {(this.props.statusLoading || this.props.incidentsLoading) && (
                        <Backdrop className="fade-in fade-out">
                            <FireworkSpinner color="#686769" size={100}/>
                        </Backdrop>
                    )}
                    <ApiStatusBlock statuses={this.props.statuses}/>
                    <IncidentsBlock client={this.state.client}
                                    apis={this.props.statuses.map(status => status.api)}
                                    incidents={this.props.incidents}/>
                </Wrapper>
            </PageWrapper>
        )
    }
}

const mapStateToProps = state => ({
    statuses: state.statuses.statuses,
    incidents: state.incidents.items,
    incidentsLoading: state.incidents.loading,
    statusLoading: state.statuses.loading,
    errors: [
        ...state.subscribe.errors,
        ...state.incidents.errors,
        ...state.statuses.errors
    ]
});

const mapActionsToProps = dispatch => ({
    loadStatuses: client => dispatch(StatusRequest(client)),
    loadIncidents: client => dispatch(IncidentsRequest(client))
});

export default withRouter(
    connect(
        mapStateToProps,
        mapActionsToProps,
    )(ClientPage),
);