import * as types from './types'

export const IncidentsRequest = client => ({
    type: types.INCIDENTS_LOAD_REQUEST,
    client
});

export const IncidentsSuccess = incidents => ({
    type: types.INCIDENTS_LOAD_SUCCESS,
    incidents
});

export const IncidentsFailure = response => ({
    type: types.INCIDENTS_LOAD_FAILURE,
    response
});

export const ToggleIncidentsLoading = () => ({
    type: types.INCIDENTS_TOGGLE_LOADING
});

export const AddIncidentRequest = incident => ({
    type: types.INCIDENT_ADD_REQUEST,
    incident
});

export const AddIncidentSuccess = incident => ({
    type: types.INCIDENT_ADD_SUCCESS,
    incident
});

export const AddIncidentFailure = response => ({
    type: types.INCIDENT_ADD_FAILURE,
    response
});

export const RemoveIncidentRequest = incidentId => ({
    type: types.INCIDENT_REMOVE_REQUEST,
    incidentId
});

export const RemoveIncidentSuccess = incidentId => ({
    type: types.INCIDENT_REMOVE_SUCCESS,
    incidentId
});

export const RemoveIncidentFailure = response => ({
    type: types.INCIDENT_REMOVE_FAILURE,
    response
});

export const EditIncidentRequest = (incidentId, incident) => ({
    type: types.INCIDENT_EDIT_REQUEST,
    incidentId,
    incident
});

export const SaveIncidentSuccess = incident => ({
    type: types.INCIDENT_EDIT_SUCCESS,
    incident
});

export const EditIncidentFailure = (incidentId, incident, error) => ({
    type: types.INCIDENT_EDIT_FAILURE,
    incidentId,
    incident,
    error
});

export const ToggleSaveIncidentLoading = () => ({
    type: types.INCIDENT_TOGGLE_SAVING
});

export const ToggleRemoveIncidentLoading = () => ({
    type: types.INCIDENT_TOGGLE_REMOVING
});

export const ToggleIncidentsEdit = incident => ({
    type: types.INCIDENT_TOGGLE_EDITING,
    incident
});