import * as types from "./types";

export const ClientsRequest = () => ({
    type: types.CLIENTS_REQUEST
});

export const ClientsSuccess = clients => ({
    type: types.CLIENTS_SUCCESS,
    clients
});

export const ClientsFailure = error => ({
    type: types.CLIENTS_SUCCESS,
    error
});