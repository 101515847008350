import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 30px;
  max-width: 800px;
  margin: auto;
  padding-bottom: 1px;

  @media (max-width: 800px) {
    margin: auto;
    padding: 30px 20px 5px 20px;
  }

  @media (max-width: 680px) {
    padding: 1px 1px;
  }
`;

export const Status = styled.p`
  margin-right: 20px;
  margin-top: 14px;
  font-size: 17px;
  color: #445d6e;

  @media (max-width: 578px) {
    max-width: 100%;
  }
`;

export const StatusContent = styled.pre`
  margin: 0;
  font-family: inherit !important;
  font-size: inherit !important;
`

export const Component = styled.div`
  margin: 0 0 10px 0;
  border-top: 1px solid #e0e4e7 !important;
  margin-bottom: 30px !important;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(80, 87, 91, 0.15);
  border-radius: 10px;
  padding-left: 15px;
`;

export const Text = styled.p`
  font-size: 30px;
  margin: 0;
  color: #445d6e;
  font-family: 'Open Sans', sans-serif;

  @media (max-width: 578px) {
    max-width: 100%;
    font-size: 25px;
  }
`;

export const Info = styled.p`
  color: #fff;
  font-size: 18px;
  font-weight: bold;
`;

export const BlockInfo = styled.div`
  height: 52px;
  max-width: 800px;
  background: #2980b7;
  padding: 15px;
  margin-bottom: 40px;
  border-radius: 10px;
`;

export const StatusSize = styled.div`
  width: 100%;
  display: inline-block;
`;

export const TextSize = styled.div`
  width: 100%;
  display: inline-block;
`;

export const Time = styled.p`
  font-size: 19px;
  margin-top: 12px;
  margin-bottom: 3px;
  color: ${({status}) => (status.toUpperCase() === 'SCHEDULED' ? '#507BCB' : '#CE5559')};
  font-family: 'Open Sans', sans-serif;

  @media (max-width: 578px) {
    max-width: 100%;
    font-size: 19px;
  }
`;
export const Created = styled.div`
  font-size: 19px;
  font-weight: 550;
  margin-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #445d6e;
  font-family: 'Open Sans', sans-serif;

  @media (max-width: 578px) {
    max-width: 100%;
    font-size: 19px;
  }
`;

export const ButtonWaraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const AddIncidentContainer = styled.div``;
export const HeadName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  border-bottom: 2px solid #e0e4e7 !important;
`;

export const Div = styled.div`
  padding-bottom: 1px;
`;
export const Button = styled.button`
  font-family: Ubuntu, sans-serif;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
  width: auto;
  min-width: 90px;
  height: 35px;
  border-radius: 25px;
  background: #0b9dcc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  outline: none;
  border: white;
  :focus {
    outline: none;
    border: white;
  }

  &:disabled {
    border-color: #abb0bd;
    background: #abb0bd;
    cursor: auto;
  }
`;

export const SmallWrap = styled.div`
  padding: 10px 0;
`;

export const NoIcident = styled.div`
  border-top: 1px solid #e0e4e7 !important;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(80, 87, 91, 0.15);
  border-radius: 10px;
  padding-left: 15px;
  font-size: 21px;
  font-weight: 550;
  margin-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #445d6e;
  font-family: 'Open Sans', sans-serif;
  padding: 30px 30px 30px 20px;

  @media (max-width: 578px) {
    max-width: 100%;
    font-size: 19px;
  }
  `;

export const MarginWrap = styled.div`
  margin-left: 10px;
  display: inline;
`;

export const IncidentHeader = styled.span`
  font-size: 21px;
`;
