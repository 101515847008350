import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import moment from "moment";
import MultiSelect from "../MultiSelect";
import PropTypes from "prop-types";

const dateFormat = "YYYY-MM-DDTHH:mm";
const statuses = [{
    name: "",
    text: ""
}, {
    name: "UNAVAILABLE",
    text: "Not Available"
}, {
    name: "SCHEDULED",
    text: "Scheduled"
}];

class AddIncidentForm extends React.Component {
    constructor(props, context, formatDate) {
        super(props, context);

        this.state = {
            minTo: this.props.incident.from ? moment(this.props.incident.from).format(dateFormat) : ""
        }
    }

    formatDate = date => {
        return date ? moment(date).format(dateFormat) : "";
    };

    handleFromChange = event => {
        this.setState({
            minTo: event.target.value ? moment(event.target.value).add(1, "minute").format(dateFormat) : ""
        });

        this.props.handleIncidentChange(event);
    };

    render() {
        return <>
            <Row>
                <Col>
                    <Form.Group controlId="from">
                        <Form.Label column={false}>From</Form.Label>
                        <Form.Control name="from"
                                      type="datetime-local"
                                      required
                                      defaultValue={this.formatDate(this.props.incident.from)}
                                      onChange={this.handleFromChange}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="to">
                        <Form.Label column={false}>To</Form.Label>
                        <Form.Control name="to"
                                      type="datetime-local"
                                      min={this.state.minTo}
                                      defaultValue={this.formatDate(this.props.incident.to)}
                                      onChange={this.props.handleIncidentChange}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="status">
                        <Form.Label column={false}>Status</Form.Label>
                        <Form.Control name="status"
                                      as="select"
                                      required
                                      defaultValue={this.props.incident.status || ""}
                                      onChange={this.props.handleIncidentChange}>
                            {statuses.map((status, index) => (
                                <option key={index} value={status.name}>{status.text}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label column={false}>Description</Form.Label>
                        <Form.Control name="description"
                                      required
                                      as="textarea"
                                      rows="6"
                                      defaultValue={this.props.incident.description || ""}
                                      onChange={this.props.handleIncidentChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <MultiSelect name="apis"
                                 required
                                 label="API"
                                 items={this.props.availableApis}
                                 selectedItems={this.props.incident.apis || []}
                                 handleChange={this.props.handleApiSelectChange}/>
                </Col>
            </Row>
        </>
    }
}

const defaultProps = {
    incident: {},
    create: false
};

const propTypes = {
    availableApis: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleIncidentChange: PropTypes.func.isRequired,
    handleApiSelectChange: PropTypes.func.isRequired,
    incident: PropTypes.shape({
        apis: PropTypes.arrayOf(PropTypes.string),
        status: PropTypes.oneOf(statuses.map(status => status.name)),
        description: PropTypes.string,
        from: PropTypes.string,
        to: PropTypes.string
    })
};

AddIncidentForm.propTypes = propTypes;
AddIncidentForm.defaultProps = defaultProps;

export default AddIncidentForm
