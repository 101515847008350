import * as types from "../actions/types";

const initialState = {
    subscription: {},
    loading: false,
    errors: []
};

const subscribeReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SUBSCRIBE_REQUEST:
        case types.SUBSCRIPTION_GET_REQUEST:
        case types.SUBSCRIPTION_CONFIRM_REQUEST:
        case types.UNSUBSCRIBE_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case types.SUBSCRIBE_SUCCESS:
        case types.SUBSCRIPTION_CONFIRM_SUCCESS:
        case types.SUBSCRIPTION_GET_SUCCESS:
        case types.UNSUBSCRIBE_SUCCESS: {
            return {
                ...state,
                loading: false,
                subscription: action.subscription
            }
        }
        case types.SUBSCRIBE_FAILURE:
        case types.SUBSCRIPTION_GET_FAILURE:
        case types.SUBSCRIPTION_CONFIRM_FAILURE:
        case types.UNSUBSCRIBE_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.response.errors
            }
        }
        default:
            return state
    }
};

export default subscribeReducer