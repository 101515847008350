import React from 'react'
import {Route} from 'react-router'

class CustomRoute extends React.Component {
    render() {
        const {component: Component, ...rest} = this.props;

        return <Route render={props => <Component {...rest} />}/>
    }
}

export default CustomRoute