import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {FireworkSpinner} from "react-spinners-kit";
import {Center, Component, H2, Sidebar, Span, Wrapper} from "./styled";
import {ClientsRequest} from "../../store/actions/clients.actions";
import {IncidentsRequest} from "../../store/actions/incidents.actions";
import {IncidentsBlock, PageWrapper} from "../../components";

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clientsLoading: false,
            incidentsLoading: false,
            errors: [],
            notify: [],
            sticky: false,
            loading: true
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if (this.props.isLoggedIn) {
            this.props.getAllIncidents();
            this.props.getClients();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn) {
            this.props.getAllIncidents();
            this.props.getClients();
        }

        if (prevProps.errors.length === 0 && this.props.errors.length > 0) {
            const notify = this.props.errors.map(error => ({
                type: "error",
                message: error.message
            }));

            this.setState({
                notify: notify
            })
        }
    }

    handleScroll = () => {
        const sticky = (window.scrollY > 140);
        this.setState({
            sticky: sticky
        });
    };

    render() {
        let sortedClients = this.props.clients.sort((a, b) => a.localeCompare(b));
        return (
            <PageWrapper title="API Clients" notify={this.state.notify}>
                <Wrapper>
                    {this.props.isLoggedIn ? (
                        <>  {this.props.incidentsLoading || this.props.clientsLoading ? (
                            <Center>
                                <FireworkSpinner color="#686769"
                                                 size={100}
                                                 loading={this.props.incidentsLoading || this.props.clientsLoading}/>
                            </Center>
                        ) : (
                            <IncidentsBlock global={true}
                                            client={[]}
                                            clients={this.props.clients}
                                            apis={null}
                                            incidents={this.props.incidents}/>
                        )}
                        </>
                    ) : (
                        <Span>Please login to see list of clients</Span>
                    )}
                </Wrapper>
                {this.props.isLoggedIn && (
                    <Sidebar className={this.state.sticky ? 'sticky' : ''}>
                        <H2>{'Clients'}</H2>
                        {sortedClients && (
                            <Component className="scrolling">
                                {sortedClients.map((client, index) => (
                                    <Link key={index} to={`/${client}`}>
                                        <Component className="fade-in">
                                            {' '}
                                            {client}
                                        </Component>
                                    </Link>
                                ))}
                            </Component>
                        )}
                    </Sidebar>
                )}
            </PageWrapper>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
    clients: state.clients.clients,
    incidents: state.incidents.items,
    clientsLoading: state.clients.loading,
    incidentsLoading: state.incidents.loading,
    errors: state.clients.errors
});

const mapActionsToProps = dispatch => ({
    getClients: () => dispatch(ClientsRequest()),
    getAllIncidents: () => dispatch(IncidentsRequest())
});

export default withRouter(
    connect(
        mapStateToProps,
        mapActionsToProps,
    )(HomePage)
)