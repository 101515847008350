import styled from 'styled-components'

export const ActionsBlock = styled.div`
    button.btn:disabled {
        cursor: default;
    }
`;

export const SaveContainer = styled.div`
    display: flex;
    align-items: center;
    justiy-content: between;
    
    button {
        min-width: 100px;
    }
    
    button.save {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;