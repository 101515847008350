import styled from 'styled-components'

export const Container = styled.div` 
    width: 650px;
    margin-top: 25px!important;
    margin: auto;
    border: 1px solid gray;
    background-color: #fff;
    border-radius: 10px;

    @media (max-width: 720px) {
        margin-bottom: 15px;
        width: 550px;
    }

    @media (max-width: 480px) {
        width: 375px;
    }
`;
export const Wrapper = styled.div`
    width: 85%;
    margin: auto;
    padding: 30px 10px 30px 10px;
`;

export const Close = styled.button`
    color: red;
    float: right;
    border: none;
    border-radius: 10px;

    &:focus {
      outline: none;
    }
`;

export const InputWrapper = styled.form`  
  top: 15%;   
`;

export const Text = styled.p`
  font-size: 32px;
  color: #445d6e;
  font-family: 'Open Sans',sans-serif;
  margin-bottom: 5px;
`;

export const SwitchGroupTitle = styled.p`
  font-size: 18px;
  color: #445d6e;
  font-family: 'Open Sans',sans-serif;
  margin-bottom: 5px;
`;

export const Input = styled.input` 
  margin: auto; 
  background: white;
  display: inline-block;
  border: 1px solid #bbb;
  padding: 1px 10px 0;
  height: 40px;
  width: 100%;
  margin-top: 15px;

  &:focus {
      outline: none;
    }
`;

export const SubmitButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    height: 40px;
    transition: color .1s ease-out 0s,
    background-color .1s ease-out 0s,
    border-color .1s ease-out 0s,
    box-shadow .2s ease-out;
    font-size: 19px;
    font-family: Open Sans,Arial,sans-serif;
    font-weight: 400;
    line-height: 1em;
    padding: 10px 16px;
    text-transform: uppercase;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    border: 1px solid #fff;
    background-color: #3498db;
    margin: 15px auto 0;
    width: 100%;

    &:hover {
      box-shadow: 2px 1px 2px 1px rgba(65,24,39,0.1)
    }

    &:focus {
      outline: none;
    }

    &:disabled {
        border-color: #abb0bd;
        background: #abb0bd;
        cursor: auto;
    }
`;

export const Label = styled.label`
  display: inline;
  font-size: 19px;
  color: #445d6e;
  font-family: 'Open Sans',sans-serif;
`;

export const Privacy = styled.label`
  font-size: 19px;
  text-decoration: underline;
  color: #1f6089;
  font-family: 'Open Sans',sans-serif;
`;

export const Checkbox = styled.input`
  height: 16px;
`;

export const Radio = styled.input`
  display: inline-block;
  height: 16px;
`;

export const InputGroup = styled.div`
    margin: auto;
    padding: 10px 0px 10px 0px;
`;

export const RadioGroup = styled.div`
    margin: auto;
    padding: 0px 5px 0px 10px;
`;
