import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 30px;
  max-width: 800px;
  margin: auto;
  transition: height 1s ease;

  @media (max-width: 800px) {
    margin: auto;
    padding: 30px 20px 5px 20px;
  }

  @media (max-width: 680px) {
    padding: 1px 1px;
  }
`;

const apiStatusColor = {
    INIT: '#33D5D4',
    UP: '#46B05B',
    DOWN: '#DC3545'
};

export const Status = styled.p`
  margin-right: 23px;
  margin-top: 14px;
  font-size: 17px;
  color: ${({status}) => apiStatusColor[status]};
  float: right !important;

  @media (max-width: 578px) {
    max-width: 43%;
  }
`;

export const Component = styled.div`
  margin: 0 0 10px 0;
  border-bottom: 1px solid #e0e4e7 !important;
  display: flex;
`;

export const Text = styled.p`
  font-size: 23px;
  margin-top: 12px;
  margin-left: 10px;
  color: #445d6e;
  font-family: 'Open Sans', sans-serif;

  @media (max-width: 578px) {
    max-width: 100%;
    font-size: 19px;
  }
`;

export const Info = styled.p`
  color: #fff;
  font-size: 18px;
  font-weight: bold;
`;

export const BlockInfo = styled.div`
  height: 52px;
  transition: height 1s ease;
  max-width: 800px;
  background: ${({warn}) => (warn ? '#f0932b' : '#2980b7')};
  padding: 15px;
  margin-bottom: 40px;
  border-radius: 10px;

  @media (max-width: 480px) {
    height: 80px;
    margin-top: 15px;
  }
`;

export const StatusSize = styled.div`
  float: left;
  width: 25%;
`;

export const TextSize = styled.div`
  float: left;
  width: 75%;

  @media (max-width: 578px) {
    width: 70%;
  }
`;