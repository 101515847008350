import axios from "axios";
import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import rootReducer from "./reducers";
import Amplify from "aws-amplify";

const COGNITO = {
    mandatorySignIn: false,
    region: "eu-central-1",
    userPoolId: "eu-central-1_qm782koZP",
    identityPoolId: "eu-central-1:7811d0f6-d3ce-4b6c-b4b3-1e226a830266",
    userPoolWebClientId: "9p6itoh4ik3ku9dsrscmg43c3"
}

Amplify.configure({
    Auth: COGNITO,
    Analytics: {
        disabled: true
    }
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

axios.interceptors.request.use(
    function (config) {
        const auth = store.getState().auth;

        if (auth.isLoggedIn) {
            const jwtToken = auth.user.signInUserSession.idToken.jwtToken;

            config.headers["Authorization"] = `Bearer ${jwtToken}`;
        }

        config.baseURL = window.env.API_URL;

        return config
    },
    function (error) {
        return Promise.reject(error)
    },
);

axios.interceptors.response.use(response => response, error => {
    const isResponseError = error.constructor === Error && Object.keys(error).length > 0 && error.hasOwnProperty("response");

    let wrappedError;

    if (isResponseError) {
        wrappedError = error.response.data;
    } else {
        const isClientError = error.constructor === Error;

        if (isClientError) {
            wrappedError = {
                errors: [{
                    message: error.message,
                    reason: error.stack
                }]
            }
        } else {
            wrappedError = {
                errors: [{
                    message: JSON.stringify(error)
                }]
            }
        }
    }

    return Promise.reject(wrappedError);
});

export default store