import * as types from './types'

export const SubscribeRequest = (email, client, timezone, incidentsEnabled, downtimesEnabled, downtimeReportType) => ({
    type: types.SUBSCRIBE_REQUEST,
    email,
    client,
    timezone,
    incidentsEnabled,
    downtimesEnabled,
    downtimeReportType
});

export const SubscribeSuccess = subscription => ({
    type: types.SUBSCRIBE_SUCCESS,
    subscription
});

export const SubscribeFailure = response => ({
    type: types.SUBSCRIBE_FAILURE,
    response
});

export const UnsubscribeRequest = subscriptionId => ({
    type: types.UNSUBSCRIBE_REQUEST,
    subscriptionId
});

export const UnsubscribeSuccess = subscription => ({
    type: types.UNSUBSCRIBE_SUCCESS,
    subscription
});

export const UnsubscribeFailure = response => ({
    type: types.UNSUBSCRIBE_FAILURE,
    response
});

export const GetSubscriptionRequest = subscriptionId => ({
    type: types.SUBSCRIPTION_GET_REQUEST,
    subscriptionId
});

export const GetSubscriptionSuccess = subscription => ({
    type: types.SUBSCRIPTION_GET_SUCCESS,
    subscription
});

export const GetSubscriptionFailure = response => ({
    type: types.SUBSCRIPTION_GET_FAILURE,
    response
});

export const ConfirmSubscriptionRequest = (subscriptionId, code) => ({
    type: types.SUBSCRIPTION_CONFIRM_REQUEST,
    subscriptionId,
    code
});

export const ConfirmSubscriptionSuccess = subscription => ({
    type: types.SUBSCRIPTION_CONFIRM_SUCCESS,
    subscription
});

export const ConfirmSubscriptionFailure = response => ({
    type: types.SUBSCRIPTION_CONFIRM_FAILURE,
    response
});