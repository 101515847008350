import React from 'react';
import {Container, Div, Text, Wrapper} from './styled.js';

const Footer = () => {
    return (
        <React.Fragment>
            <Div>
                <Container>
                    <Wrapper>
                        <Text>(c) 2022 NDGIT GmbH</Text>
                    </Wrapper>
                </Container>
            </Div>
        </React.Fragment>
    );
};

export default Footer;
