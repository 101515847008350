import React from "react";
import {H1, H2, Text} from "./styled";

const Terms = () => (
    <>
        <H1>
            Privacy Policy of NDGIT GmbH
        </H1>
        <Text>
            NDGIT GmbH operates the ndgit-status.com website, which
            provides uptime information of the mandator's PSD2 API.
        </Text> <br/>
        <Text>
            If you choose to use our Service, then you agree to the
            collection and use of information in relation with this policy.
            The Personal Information that we collect are used for providing
            and improving the Service. We will not use or share your information
            with anyone except as described in this Privacy Policy.
        </Text>
        <H2>
            Information Collection and Use
        </H2>
        <Text>
            For a better experience while using our Service, we may require
            you to provide us with certain personally identifiable information
            limited to your email. The information that we collect will be to
            contact you as described in the subscription info box..
        </Text>
        <H2>
            Log Data
        </H2>
        <Text>
            We want to inform you that whenever you visit our Service, we collect
            information that your browser sends to us that is called Log Data.
            This Log Data may include information such as your computer’s Internet
            Protocol ("IP") address, browser version, pages of our Service that you visit,
            the time and date of your visit, the time spent on those pages, and other statistics.
        </Text>
        <H2>
            Contact Us
        </H2>
        <Text>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to&nbsp;
            <a href="mailto:info@ndgit.com"
               className="emailContactus">
                <span className="contactus">
                    contact us.
                </span>
            </a>
        </Text>
    </>
);

export default Terms