import * as types from '../actions/types'

const initialState = {
    clients: [],
    loading: false,
    errors: []
};

const clientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CLIENTS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case types.CLIENTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                clients: action.clients
            }
        }
        case types.CLIENTS_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.response.errors
            }
        }
        default:
            return state
    }
};

export default clientsReducer