import {all, call, put, takeEvery} from 'redux-saga/effects'
import * as types from '../actions/types'
import {
    AddIncidentFailure,
    AddIncidentSuccess,
    EditIncidentFailure,
    IncidentsFailure,
    IncidentsSuccess,
    RemoveIncidentFailure,
    RemoveIncidentSuccess,
    SaveIncidentSuccess,
    ToggleIncidentsLoading,
    ToggleRemoveIncidentLoading,
    ToggleSaveIncidentLoading,
} from '../actions/incidents.actions'
import {addIncident, editIncident, getIncidents, removeIncident} from '../fetches/incidents.fetch'

function* getIncidentsRequest(action) {
    yield put(ToggleIncidentsLoading());

    try {
        const {data} = yield call(getIncidents, action.client);

        yield put(IncidentsSuccess(data))
    } catch (error) {
        yield put(IncidentsFailure(error))
    } finally {
        yield put(ToggleIncidentsLoading())
    }
}

function* addIncidentRequest(action) {
    yield put(ToggleSaveIncidentLoading());

    try {
        const {data} = yield call(addIncident, action.incident);
        yield put(AddIncidentSuccess(data));
    } catch (error) {
        yield put(AddIncidentFailure(error));
    } finally {
        yield put(ToggleSaveIncidentLoading())
    }
}

function* editIncidentRequest(action) {
    yield put(ToggleSaveIncidentLoading());

    try {
        const {data} = yield call(editIncident, action.incidentId, action.incident);
        yield put(SaveIncidentSuccess(data));
    } catch (error) {
        yield put(EditIncidentFailure(action.incidentId, action.incident, error));
    } finally {
        yield put(ToggleSaveIncidentLoading())
    }
}

function* removeIncidentRequest(action) {
    yield put(ToggleRemoveIncidentLoading());

    try {
        yield call(removeIncident, action.incidentId);
        yield put(RemoveIncidentSuccess(action.incidentId));
    } catch (error) {
        yield put(RemoveIncidentFailure(error));
    } finally {
        yield put(ToggleRemoveIncidentLoading())
    }
}

export function* incidentsSaga() {
    yield all([
        takeEvery(types.INCIDENTS_LOAD_REQUEST, getIncidentsRequest),
        takeEvery(types.INCIDENT_ADD_REQUEST, addIncidentRequest),
        takeEvery(types.INCIDENT_EDIT_REQUEST, editIncidentRequest),
        takeEvery(types.INCIDENT_REMOVE_REQUEST, removeIncidentRequest)
    ])
}