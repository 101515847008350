import React from "react";
import {
    AddIncidentContainer,
    ButtonWaraper,
    Component,
    Created,
    Div,
    HeadName,
    IncidentHeader,
    NoIcident,
    SmallWrap,
    Status,
    StatusContent,
    StatusSize,
    Text,
    TextSize,
    Time,
    Wrapper
} from "./styled";
import moment from "moment";
import {connect} from "react-redux";
import {Form} from "react-bootstrap";
import {
    AddIncidentRequest,
    EditIncidentRequest,
    RemoveIncidentRequest,
    ToggleIncidentsEdit
} from "../../store/actions/incidents.actions";
import AddIncidentForm from "./assets/AddIncidentForm";
import AddGlobalIncidentForm from "./assets/AddGlobalIncidentForm";
import Actions from "./assets/Actions";
import {StatusRequest} from "../../store/actions/status.actions";
import {getStatuses} from "../../store/fetches/status.fetch";
import ReactTooltip from "react-tooltip";

class IncidentsBlock extends React.Component {
    timeFormat = "D MMM YYYY HH:mm";

    constructor(props) {
        super(props);

        this.state = {
            adding: false,
            editing: false,
            removing: false,
            saving: false,
            loadingApis: true,
            apiList: {}
        }
    }

    componentDidMount() {
        if (this.props.global) {
            this.props.clients.forEach(client => {
                if (!this.state.apiList[client]) {
                    this.loadApis(client);
                }
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let update = {};

        ["adding", "editing", "removing", "saving"].forEach(action => {
            if (prevProps[action] !== this.props[action]) {
                update[action] = this.props[action];
            }
        });

        if (Object.keys(update).length > 0) {
            this.setState(update);
        }
    }

    /**
     * Set state and update current incident in props
     * to show modal window to confirm remove of specified incident
     *
     * @param incident an instance to be removed
     */
    toggleConfirm = incident => {
        Object.keys(incident).forEach(key => {
            this.props.incident[key] = incident[key]
        });

        this.setState({
            removing: !this.state.removing
        });
    };

    /**
     * Remove incident, previously saved in props by {@link #toggleConfirm}
     * Called by click on "YES" in confirm incident remove modal
     *
     * @see Actions
     */
    handleRemoveIncident = event => {
        event.preventDefault();

        this.props.removeIncident(this.props.incident.incidentId);
    };

    handleIncidentChange = event => {
        event.persist();

        this.props.incident[event.target.name] = event.target.value;
    };

    handleApiSelectChange = selectedApis => {
        this.props.incident.apis = selectedApis.map(api => api.label);
    };

    handleClientSelectChange = selectedClients => {
        this.props.incident.clients = selectedClients.map(client => client.label);
        this.props.incident.clients.forEach(client => {
            if (!this.state.apiList[client]) {
                this.loadApis(client);
            }
        })
    };

    /**
     * Submit edited incident to backend, if it was changed
     * Incident data to submit is stored in props
     *
     * @param event instance of browser event, needed to prevent from HTML-based submit
     */
    handleEditSubmit = event => {
        event.preventDefault();
        const updated = this.props.incident;
        const clients = [];
        if ((this.props.global && !updated.incidentId) ||
            (this.props.global && updated.groupId)
        ) {
            updated.clients.forEach(client => {
                clients.push({name: client, apis: this.state.apiList[client]})
            });
        } else {
            const client = this.props.global ? this.props.incident.client : this.props.client;
            clients.push({name: client, apis: updated.apis})
        }
        const incident = {
            clients: clients,
            status: updated.status,
            from: new Date(updated.from).toISOString(),
            to: updated.to ? new Date(updated.to).toISOString() : undefined,
            description: updated.description,
            groupId: updated.groupId
        };
        if (updated.incidentId !== undefined) {
            this.props.editIncident(updated.incidentId, incident);
        } else {
            this.props.addIncident(incident);
        }
    };

    handleEditToggle = incident => this.props.toggleEdit(incident);

    formatTimeRange = incident => {
        let to;

        if (incident.to) {
            const sameDay = moment(incident.from).isSame(incident.to, "day");
            const precessedTo = sameDay ? moment(incident.to).format("HH:mm") : moment(incident.to).format(this.timeFormat);

            to = ` to ${precessedTo}`;
        }

        return `from ${moment(incident.from).format(this.timeFormat)}${to || ""}`
    };

    loadApis(client) {
        this.setState(prevState => ({
            apiList: {
                ...prevState.apiList,
                [client]: []
            }
        }));

        getStatuses(client).then(res => {
            this.setState(prevState => ({
                apiList: {
                    ...prevState.apiList,
                    [client]: res.data.map(status => status.api)
                }
            }))
        })
    }

    render() {
        return (
            <React.Fragment>
                <Wrapper className="fade-in">
                    <AddIncidentContainer>
                        <Form onSubmit={e => this.handleEditSubmit(e)}>
                            <HeadName>
                                <Text>Incidents</Text>
                                {this.props.isLoggedIn && (
                                    <Actions item={this.props.incident}
                                             saving={this.state.saving}
                                             editing={this.state.editing}
                                             opened={this.state.adding}
                                             openText={<i className="fa fa-plus-square"/>}
                                             toggleEdit={this.handleEditToggle}/>
                                )}
                            </HeadName>
                            {this.props.adding && (
                                <>
                                    {this.props.global ? (
                                        <AddGlobalIncidentForm availableApis={this.props.apis}
                                                               create={true}
                                                               clients={this.props.clients}
                                                               incident={this.props.incident}
                                                               handleIncidentChange={this.handleIncidentChange}
                                                               handleClientsSelectChange={this.handleClientSelectChange}/>
                                    ) : (
                                        <AddIncidentForm availableApis={this.props.apis}
                                                         create={true}
                                                         incident={this.props.incident}
                                                         handleIncidentChange={this.handleIncidentChange}
                                                         handleApiSelectChange={this.handleApiSelectChange}/>
                                    )}
                                </>
                            )}
                        </Form>
                    </AddIncidentContainer>
                    {this.props.incidents.length === 0 ?
                        <NoIcident>No Incidents</NoIcident> :
                        this.props.incidents.map(incident => (
                            <React.Fragment key={incident.data.incidentId}>
                                <Form onSubmit={e => this.handleEditSubmit(e)}>
                                    <Created>
                                        <IncidentHeader>
                                            {incident.data.groupId ? incident.data.clients.join(', ') : incident.data.client}
                                            {' - ' + moment(incident.data.createdAt).format('MMM D, YYYY')}
                                        </IncidentHeader>
                                        {this.props.isLoggedIn && (
                                            <ButtonWaraper
                                                data-tip={(!this.props.global && incident.data.groupId && !this.state.removing) ?
                                                    "Editing global incidents is only available on the global incidents list"
                                                    : ""}>
                                                <Actions item={incident.data}
                                                         saving={this.state.saving}
                                                         editing={this.state.editing}
                                                         removing={this.state.removing}
                                                         opened={incident.edit}
                                                         openText={<i className="fa fa-edit"/>}
                                                         toggleEdit={this.handleEditToggle}
                                                         removeIncident={this.handleRemoveIncident}
                                                         toggleConfirm={this.toggleConfirm}
                                                         inactive={!this.props.global && incident.data.groupId}/>
                                                <ReactTooltip/>
                                            </ButtonWaraper>
                                        )}
                                    </Created>
                                    <SmallWrap/>
                                    {incident.edit ? (
                                        <>
                                            {incident.data.groupId ? (
                                                <AddGlobalIncidentForm availableApis={this.props.apis}
                                                                       clients={this.props.clients}
                                                                       incident={incident.data}
                                                                       handleIncidentChange={this.handleIncidentChange}
                                                                       handleClientsSelectChange={this.handleClientSelectChange}/>
                                            ) : (
                                                <AddIncidentForm availableApis={this.props.apis
                                                || this.state.apiList[incident.data.client]}
                                                                 incident={incident.data}
                                                                 handleIncidentChange={this.handleIncidentChange}
                                                                 handleApiSelectChange={this.handleApiSelectChange}/>
                                            )}
                                        </>
                                    ) : (
                                        <Div>
                                            <Component>
                                                <TextSize>
                                                    {incident.data.status === 'SCHEDULED' ? (
                                                        <Time status={incident.data.status}>
                                                            {`Scheduled Maintenance for API${incident.data.apis.length > 1 ? "s"
                                                                : ""} ${incident.data.apis.join(", ")}
                                                            ${this.formatTimeRange(incident.data)}`}
                                                        </Time>
                                                    ) : (
                                                        <Time status={incident.data.status}>
                                                            {`${incident.data.apis} not available
                                                            ${this.formatTimeRange(incident.data)}`}
                                                        </Time>
                                                    )}
                                                </TextSize>
                                                <StatusSize>
                                                    <Status>
                                                        <StatusContent>
                                                            {incident.data.description}
                                                        </StatusContent>
                                                    </Status>
                                                </StatusSize>
                                            </Component>
                                        </Div>
                                    )}
                                </Form>
                            </React.Fragment>
                        ))
                    }
                </Wrapper>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
    adding: state.incidents.adding,
    editing: state.incidents.editing,
    removing: state.incidents.removing,
    saving: state.incidents.saving,
    incident: state.incidents.incident,
    statuses: state.statuses.statuses
});

const mapActionsToProps = dispatch => ({
    addIncident: incident => dispatch(AddIncidentRequest(incident)),
    editIncident: (incidentId, incident) => dispatch(EditIncidentRequest(incidentId, incident)),
    toggleEdit: incident => dispatch(ToggleIncidentsEdit(incident)),
    removeIncident: incidentId => dispatch(RemoveIncidentRequest(incidentId)),
    loadStatuses: client => dispatch(StatusRequest(client))
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(IncidentsBlock)