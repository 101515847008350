import axios from 'axios'

/**
 * Execute POST request to %baseUrl%/subscriptions to create subscription for email to
 * It creates entry in 'subscriptions' table, if everything ok.
 * Note: client should exist before subscription request executed
 * Note: if subscription isn't yet exists - it creates automatically and implicitly entry in 'subscriptions' table
 *
 * @param email a user email the subscription should be created for
 * @param client name of the API (client) used for subscription
 * @return {Promise<AxiosResponse<T>>}
 */
export const subscribe = (email, client, timezone, incidentsEnabled, downtimesEnabled, downtimeReportType) => axios
    .post("subscriptions", {
        email: email,
        client: client,
        timezone: timezone,
        incidentsEnabled: incidentsEnabled,
        downtimesEnabled: downtimesEnabled,
        downtimeReportType: downtimeReportType,
    });

/**
 * Execute DELETE request to '%baseUrl%/subscriptions/subscriptionId' to unsubscribe user from specified client status updates
 *
 * @param subscriptionId id of subscription
 * @return {Promise<AxiosResponse<T>>}
 */
export const unsubscribe = subscriptionId => axios
    .delete(`subscriptions/${subscriptionId}`)
    .then(response => response.data);

/**
 * Execute GET request to '%baseUrl%/subscriptions/$userId/$clientId' to check the status of confirmation
 * @param subscriptionId id of subscription
 * @return {Promise<AxiosResponse<T>>}
 */
export const getSubscription = subscriptionId => axios
    .get(`subscriptions/${subscriptionId}`)
    .then(response => response.data);

export const confirmSubscription = (subscriptionId, code) => axios
    .patch(`subscriptions/${subscriptionId}`, {
        code: code,
        status: "CONFIRMED"
    })
    .then(response => response.data);