import styled from 'styled-components'

export const Wrap = styled.div`
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 150px 130px 150px 95px;

  @media (max-width: 680px) {
    padding: 5px 30px 30px 30px;
  }
`;

export const Container = styled.div`
  width: 100%;  
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #9053c7;
  background: #f0f0f0
`;

export const LoginPicture = styled.div`
    width: 316px;
    max-width: 100%;
`;

export const LoginForm = styled.form`
    width: 316px;
    max-width: 100%;
`;

export const Password = styled.div`
    position: relative;
`;

export const Mail = styled.div`
    position: relative;
    margin-bottom: 20px;
`;

export const MailInput = styled.input`
    font-family: Ubuntu, sans-serif;
    font-size: 15px;
    line-height: 1.5;
    color: #666666;
    display: block;
    width: 100%;
    background: #e6e6e6;
    height: 50px;
    border-radius: 25px;
    padding: 0 30px 0 68px;
    outline: none;
    border: white;
    
    @media (max-width: 680px) {
        text-align: center;
        padding: 0 0 0 0;
  }
`;

export const PasswordInput = styled.input`
    font-family: Ubuntu, sans-serif;
    font-size: 15px;
    line-height: 1.5;
    color: #666666;
    display: block;
    width: 100%;
    background: #e6e6e6;
    height: 50px;
    border-radius: 25px;
    padding: 0 30px 0 68px;
    outline: none;
    border: white;

    @media (max-width: 680px) {
        text-align: center;
        padding: 0 0 0 0;
  }
`;

export const Title = styled.div`
    font-family: Ubuntu, sans-serif;
    font-size: 24px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    width: 100%;
    display: block;
    padding-bottom: 54px;

    @media (max-width: 680px) {
        padding-bottom: 30px;
        padding-top: 30px;
  }
`;

export const LoginButton = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
    outline: none;
`;

export const Button = styled.button`
    font-family: Ubuntu, sans-serif;
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #0b9dcc;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    outline: none;
    border: white;
    :focus {
        outline: none;
        border: white;
    }

    &:disabled {
        border-color: #abb0bd;
        background: #abb0bd;
        cursor: auto;
    }
`;